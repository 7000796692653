.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem var(--bs-primary-border-subtle);
}
.btn-close:focus {
	box-shadow: none !important;
}
.btn.uploadSignature {
	left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
	width: 130px;
}
.btn-outline-primary {
	background-color: var(--bs-white);
}
button.requestBuilder-button .bi::before, 
button.requestBuilder-button [class^="bi-"]::before, 
button.requestBuilder-button [class*=" bi-"]::before,
button.clearScribble-button .bi::before,
button.clearScribble-button [class^="bi-"]::before, 
button.clearScribble-button [class*=" bi-"]::before {
	display: flex;
}
button.btn.pdf-preparation-dialog-buttons-border {
	background-color: var(--bs-white);
	border: 1px solid #DEE2E6;
	opacity: 1;
}
button.btn.pdf-preparation-dialog-buttons-border:disabled em {
	color: #6C757D;
}
button.clearScribble-button {
	margin-left: 90% !important;
	position: absolute !important;
}
button.file-upload:hover,
button.person:hover,
button.action:hover {
	background-color: var(--primaryhovercolour) !important;
    border-radius: 0px;
	color:  var(--bs-white) !important;
}
button.goToLogin {
	display: flex;
    justify-content: center;
    margin: 7px auto auto !important;
    min-width: 150px;
}
button.navbar-toggler:focus {
	box-shadow: none !important;
    outline: none !important;
}
button.dropdown-item.dropdown-header:hover {
	background: transparent !important;
	color: var(--bs-dropdown-header-color) !important;
	cursor: default !important;
}
button.pdf-preparation-dialog-buttons:nth-child(1) {
	margin-right: 25px;
}
button.pdf-preparation-dialog-buttons:nth-child(1):hover {
    background-color: var(--bs-btn-hover-bg) !important;
    border-color: var(--bs-btn-hover-border-color) !important;
	color: var(--bs-btn-hover-color) !important;
}
button.pdf-preparation-dialog-buttons:nth-child(2) {
	margin-left: 25px;
}
button.pdf-preparation-dialog-buttons:nth-child(2):hover {
    background-color: var(--bs-btn-bg) !important;
    border-color: var(--bs-btn-bg) !important;
	color: var(--bs-btn-hover-color) !important;
}
button.recoveryCodes,
button.authenticatorSecret {
	height: auto !important;
}
button.recoveryCodes span,
button.authenticatorSecret span {
	white-space: normal;
}
button.requestBuilder-button, button.clearScribble-button {
    background: var(--bs-white) 0% 0% no-repeat padding-box;
	border: 1px solid var(--bs-secondary);
	border-radius: 4px;
	height: 32px;
	opacity: 1;
    width: 32px;
}
button#chooseLocation {
	line-height: 31px !important;
	left: 2px;
}
button.btn-dropdown-disabled {
	background-color: var(--bs-secondary-bg) !important;
}

button span.breakingRecoveryCodes  {
	word-spacing: 100vw;
	cursor: text;
}
button.breakingRecoveryCodesButton span.breakingRecoveryCodes em {
	cursor: pointer;
}
.colorpicker button.color {
	width: 49px;
	height: 31px;
}
.colorpicker button.picker {
    width: 49px;
    height: 31px;
    border: 1px solid var(--pki-border-color);
    border-radius: 5px 5px 5px 5px;
    opacity: 1;
}

// Add extra inset to position tooltips right
.bs-tooltip-top {
	inset: auto auto 5px 0px !important;
}

.bs-tooltip-bottom {
	inset: 5px auto auto 0px !important;
}