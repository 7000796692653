#actionIndicator {
	margin-left: 12px;
}
.actionlist-margin {
	margin-left: 24px;
}
.actionlistbar {
	align-items: center !important;
	height: 100vh !important;
	margin-top: -10px;
	position: sticky;
	top: 0px;
}
.actions-perform-action {
	position: absolute;
	z-index: 1000 !important;
}
canvas.signature-pad-canvas, canvas#signatureImageCanvas {
    background: transparent !important;
    border: none !important;
}
canvas#signatureImageCanvas {
	margin-left: -10px;
}
.certificateList {
    max-height: 400px !important;
}
td.CPSectionHead a {
	color: #fff !important;
}
.dialog img.signatureProvider {
	max-height: 50px;
	max-width: 150px;
}
.dialog .signatureProviderLogin {
	float: right;
	height: 35px;
}
.document-col {
	position: relative;
	top: 3px;
}
.documentHeader {
	border-top-left-radius: var(--pki-border-radius);
	border-top-right-radius: var(--pki-border-radius);
	font-size: var(--bs-body-font-size);
	font-weight: 400;
	height: 37px;
	line-height: 37px;
	padding-left: 20px;
	vertical-align: middle !important;

	-webkit-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.16);
	-moz-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.16);
	box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.16);
}
.document-viewer {
	margin-top: 125px;
	padding-bottom: 70px;
}
.fadeIn {
	opacity: 1;
	transition: opacity 0.2s ease-in;
}
.fadeOut {
	opacity: 0;
	transition: opacity 0.2s ease-out;
	z-index: -1 !important;
}
.first-action-icon {
	margin-top: 43px;
}
.identification-card {
    height: 267px !important;
}
img.ideal-logo {
	height: 19px !important;
}
img.signatureProvider {
	max-height: 63px !important;
	max-width: 159px !important;
}
img.stamp-image,
img.uploaded-image {
	max-height: 145px;
	max-width: 100%;
}
img.uploaded-image {
	margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.login {
    margin-top: 65px !important;
}
.performaction-navbar {	
	-webkit-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.16);
	-moz-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.16);
	box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.16);
	height: 43px !important;
	width: 100%;
	z-index: 1000;
}
.performaction-sub-navbar {
	background: var(--bs-light);
	-webkit-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.16);
	-moz-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.16);
	box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.16);
	height: 62px !important;
	margin-top: 43px;
	padding-top: 10px;
	width: 100%;
	z-index: 999;
}
.perform-action-list {
	display: flex;
	flex-direction: column;
	gap: 5px;
	min-height: 100vh;
	vertical-align: bottom;
}
.perform-action-list-item {
	font-size: 14px !important;
	height: 25px;
	padding: 4px;
	position: absolute;
	width: 25px;
}
.perform-action-dialog-header-badgenumber {
	font-size: 14px !important;
	height: 25px;
	padding: 4px;
	position: relative;
	width: 25px;
}
.perform-action-dialog-list-item {
	font-size: 14px !important;
	height: 25px;
	padding: 4px;
	position: relative;
	width: 25px;
}
.plugin-usbtoken,
object.plugin-usbtoken {
	height: 1px !important;
	position: absolute !important;
	visibility: hidden !important;
	width: 1px !important;
}
#previewtext {
	background: transparent;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 212px;
}
.preview-bold {
	font: bold 14px Helvetica !important;
}
.preview-lighter {
	font: lighter 14px Helvetica !important;
}
.provider-box {
    height: 692px !important;
}
.rendereddocumentsummary-box {
	background: var(--bs-white);	
	border-radius: var(--pki-border-radius);
	-webkit-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.16);
	-moz-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.16);
	box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.16);
	margin-bottom: 10px;
	line-height: normal;
	width: 100%;
}
.documentsummary-details {
	padding: 30px 30px 20px;
}
.documentsummary-details-mobile {
	padding: 20px;
}
.documentsummary-actor-history {
	padding: 10px 30px 10px 30px;
}
.documentsummary-current-action {
	padding: 0px 30px 30px;
}
.documentsummary-actor-line {
	padding-top: 5px;
}
.renderedSummaryButtons {
	bottom: 0px;
}
signature-pad {
	background: #EDEDED !important;
	border: none !important;
	height: 145px !important;
	width: 100%;
}
.signature-pad {
	height: 145px;
	left: 0;
	padding: 0px !important;
    position: absolute;
    top: 0;
    width: 210px;
}
span.noSignaturePadData {
	color: var(--bs-secondary);
	opacity: 0.75;
}
.spinner-border.spinner-loading {	
	border: 16px solid currentcolor;
    border-right-color: transparent;
	color: var(--bs-primary) !important;
	height: 110px;
	width: 110px;
}
.spinner-border {
	border-width: 15px;
	height: 110px;
	width: 110px;
}
.spinner-border.small {
	border-width: 4px;
	height: 25px;
	width: 25px;
}
.spinner-border.ideal-logo,
.spinner-border.dc-generate {
	border-width: 0.2em !important;
	height: 15px !important;
	width: 15px !important;
}
.spinner-border.locked {
	border-width: 4px !important;
	color: var(--bs-white);
	height: 24px !important;
	width: 24px !important;
	animation-duration: 3s;
}
.square-perform-action,
.square-perform-action:hover {
	// background: $viewer-slider-color !important;
	cursor: pointer;
	font-size: 22px;
	height: 24px;
	line-height: 22px;
	padding: 0;
	position: absolute;
	text-align: center !important;
	width: 24px;
}
.perform-action-bottom-element {
	bottom: 0px !important;
	height: 25px !important;
	display: block !important;
	background-color: var(--platformbackgroundcolour) !important;
	position: absolute !important;
	width: 30px !important;
	z-index: 1 !important;
}
.uploadedSigningImageNavContent {
	height: 205px !important;
}
.uploadContent {
	height: 145px !important;
}
.waitingtext {
    font-size: var(--font-size-heading);
}
.zoombox-viewer {
	bottom: 40px !important;
	margin-left: -32px;
}
.z-index-vline {
	z-index: 1;
}
.z-index-select {
	z-index: 9000;
}
.z-index-signaturepad-info,
.z-index-action {
	z-index: 2000;
}
.z-index-signaturepad {
	z-index: 3000;
}
.z-index-signaturepad-image {
	z-index: 3001;
}
.z-index-signaturepad-clear {
	z-index: 4001;
}
.z-index-signaturepad-text {
	z-index: 5000;
}
.z-index-signaturepad-browse {
	z-index: 5001;
}
.finishedDocumentApprover {
	opacity: .5;
}
.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating):not(.datetimepicker-icon) {
	border-right: 0px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback):not(.datetimepicker):not(.datetimepicker-icon) {
	border: var(--pki-border-color) 1px solid !important;
}

.form-range {
	width: 80%;
}

// WIGGLE EFFECT VIEWER ACTION BUTTON
button.active-action.wiggle:not(.dropdown-toggle):not(:disabled),
.button-wiggle {
	animation: wiggle 15s 2s infinite;
	-moz-animation: wiggle 15s 2s infinite;
	-webkit-animation: wiggle 15s 2s infinite;
	animation-name: wiggle;
	-moz-animation-name: wiggle;
	-webkit-animation-name: wiggle;
	animation-delay: 5s;
	-moz-animation-delay: 5s;
	-webkit-animation-delay: 5s;
}
.active-action button.wiggle:not(.dropdown-toggle):not(:disabled):hover,
.button-wiggle:hover {
	animation: none;
	animation-play-state: paused;
	-moz-animation: none;
	-moz-animation-play-state: paused;
	-webkit-animation: none;
	-webkit-animation-play-state: paused;
}

@keyframes wiggle {
	1% { transform: scale(1); }
	2% { transform: scale(0.9); }
	4% { transform: scale(1.15); }
	6% { transform: scale(1.15) rotate(-5deg); }
	8% { transform: scale(1.15) rotate(5deg); }
	10% { transform: scale(1.15) rotate(-3deg); }
	12% { transform: scale(1.15) rotate(2deg); }
	14% { transform: scale(1.15) rotate(0); }
	16% { transform: scale(1); }
}
@-moz-keyframes wiggle {
	1% { -moz-transform: scale(1); }
	2% { -moz-transform: scale(0.9); }
	4% { -moz-transform: scale(1.15); }
	6% { -moz-transform: scale(1.15) rotate(-5deg); }
	8% { -moz-transform: scale(1.15) rotate(5deg); }
	10% { -moz-transform: scale(1.15) rotate(-3deg); }
	12% { -moz-transform: scale(1.15) rotate(2deg); }
	14% { -moz-transform: scale(1.15) rotate(0); }
	16% { -moz-transform: scale(1); }
}
@-webkit-keyframes wiggle {
	1% { -webkit-transform: scale(1); }
	2% { -webkit-transform: scale(0.9); }
	4% { -webkit-transform: scale(1.15); }
	6% { -webkit-transform: scale(1.15) rotate(-5deg); }
	8% { -webkit-transform: scale(1.15) rotate(5deg); }
	10% { -webkit-transform: scale(1.15) rotate(-3deg); }
	12% { -webkit-transform: scale(1.15) rotate(2deg); }
	14% { -webkit-transform: scale(1.15) rotate(0); }
	16% { -webkit-transform: scale(1); }
}
